
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../assets/styles/Main.scss';

function Main() {

  return (
    <div className="container">
      <div className="about-section">
        <div className="image-wrapper">
          <img src="https://admitspot-email-assets.s3.ca-central-1.amazonaws.com/IMG_9904.jpeg" alt="Avatar" />
        </div>
        <div className="content">
          <div className="social_icons">
            {/* <a href="https://github.com/yujisatojr" target="_blank" rel="noreferrer"><GitHubIcon/></a> */}
            <a href="https://www.linkedin.com/in/piyushpriyadarshi1997/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
          </div>
          <h1>Piyush Priyadarshi</h1>
          <p>Lead Software Engineer | Backend Specialist | Cloud Enthusiast</p>

          <div className="mobile_social_icons">
            {/* <a href="https://github.com/yujisatojr" target="_blank" rel="noreferrer"><GitHubIcon/></a> */}
            <a href="https://www.linkedin.com/in/piyushpriyadarshi1997/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;