
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../assets/styles/Footer.scss'

function Footer() {
  return (
    <footer>
      <div>
        {/* <a href="https://github.com/yujisatojr" target="_blank" rel="noreferrer"><GitHubIcon/></a> */}
        <a href="https://www.linkedin.com/in/piyushpriyadarshi1997/" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
      </div>
      <p>Piyush Priyadarshi</p>
    </footer>
  );
}

export default Footer;