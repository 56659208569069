import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faDocker } from '@fortawesome/free-brands-svg-icons';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';

const labelsWebDevelopment = [
    "Node.js", "Next.js", "TypeScript", "PostgreSQL", "Express.js", "React", "JavaScript", "SQL", "Postman", "MongoDB", "GraphQL"
];

const labelsSecond = [
    "GitHub Actions", "NGINX", "Bitbucket Pipelines", "OAuth", "JWT"
];

const labelsCloudInfra = [
    "AWS", "EC2", "S3", "Lambda", "Route 53", "RDS", "SES", "IAM", "GCP", "BigQuery", "Dataflow", "Pub/Sub", "Cloud Compose"
];

function Expertise() {
    return (
        <div className="container" id="expertise">
            <div className="skills-container">
                <h1>Expertise</h1>
                <div className="skills-grid">
                    <div className="skill">
                        <FontAwesomeIcon icon={faReact} size="3x" />
                        <h3>Full Stack Web Development</h3>
                        <p>I am proficient in building scalable web applications using Node.js, Next.js, and React.js for both frontend and backend development and having expertise in RESTful API, SQL, NoSQL and GraphQL design to ensure seamless integration between services.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Tech stack:</span>
                            {labelsWebDevelopment.map((label, index) => (
                                <Chip key={index} className='chip' label={label} />
                            ))}
                        </div>
                    </div>

                    <div className="skill">
                        <FontAwesomeIcon icon={faCloud} size="3x" />
                        <h3>Cloud Infrastructure</h3>
                        <p>I am skilled in deploying and managing applications on AWS, ensuring high availability and scalability and with Google Cloud Platform (GCP) for data engineering workflows.
                            Proficient in cost-efficient cloud architecture, security, and monitoring with tools.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Tech stack:</span>
                            {labelsCloudInfra.map((label, index) => (
                                <Chip key={index} className='chip' label={label} />
                            ))}
                        </div>
                    </div>
                    <div className="skill">
                        <FontAwesomeIcon icon={faDocker} size="3x" />
                        <h3>DevOps & Ci/CD</h3>
                        <p>Experienced in setting up CI/CD pipelines using tools like Bitbucket Pipelines and GitHub Actions to automate deployment workflows.
                            Skilled in  environment provisioning for development, staging, and production using NGINX and cloud-native solutions.</p>
                        <div className="flex-chips">
                            <span className="chip-title">Tech stack:</span>
                            {labelsSecond.map((label, index) => (
                                <Chip key={index} className='chip' label={label} />
                            ))}
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default Expertise;